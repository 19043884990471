import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchContestRecordsService,
  fetchFSAReportService,
  fetchMarketplaceordersService,
  fetchMediaService,
  fetchYuOrderFSASalesReportService,
  fetchYuOrderOrderForFSAService,
  fetchYuSellSalesReportService,
} from '../services/dashboard.service';
import { MarketplaceOrder } from '../models/marketplace.model';
import { ContestEntry } from '../models/contestEntry.model';
import { MerchantStore } from '../models/merchantStore.model';

export const fetchMarketplaceorders = createAsyncThunk('dashboard/marketplace-orders', async (payload, thunkAPI) => {
  const response: any = await fetchMarketplaceordersService();
  if (response.isSuccessful) {
    return response.data;
  } else {
    thunkAPI.rejectWithValue("Couldn't fetch marketplace orders.");
    throw new Error("Couldn't fetch marketplace orders.");
  }
});

export const fetchContestRecords = createAsyncThunk(
  'dashboard/contestRecords',
  async (payload: { contestId: string }, thunkAPI) => {
    const response: any = await fetchContestRecordsService({ contestId: payload.contestId });
    if (response.isSuccessful) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue("Couldn't fetch contest records.");
      throw new Error("Couldn't fetch contest records.");
    }
  },
);

export const fetchYuOrderFSASalesReport = createAsyncThunk(
  'dashboard/FSASalesReport',
  async (payload: { supplierCode: string; startDate: Date; endDate: Date }, thunkAPI) => {
    const response: any = await fetchYuOrderFSASalesReportService({ ...payload });
    if (response.isSuccessful) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue("Couldn't fetch FSA sales report.");
      throw new Error("Couldn't fetch FSA sales report.");
    }
  },
);

export const fetchYuSellSalesReport = createAsyncThunk(
  'dashboard/YuSellSalesReport',
  async (payload: { beneficiaryType: string }, thunkAPI) => {
    const response: any = await fetchYuSellSalesReportService({ ...payload });
    if (response.isSuccessful) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue("Couldn't fetch sales data.");
      throw new Error("Couldn't fetch sales data.");
    }
  },
);

export const fetchYuOrderOrderForFSA = createAsyncThunk(
  'dashboard/searchOrder',
  async (payload: { supplierCode: string; searchQuery: string }, thunkAPI) => {
    const response: any = await fetchYuOrderOrderForFSAService({ ...payload });
    if (response.isSuccessful) {
      return response.data;
    } else {
      thunkAPI.rejectWithValue("Couldn't fetch order details.");
      throw new Error("Couldn't fetch order details.");
    }
  },
);

export const fetchFSAReport = createAsyncThunk('dashboard/FSAReport', async (payload, thunkAPI) => {
  const response: any = await fetchFSAReportService();
  if (response.isSuccessful) {
    const report_info_reponse = response.data.data;
    // downnload file
    const file_response: any = await fetchMediaService({
      fileName: report_info_reponse.reportFile.fileName,
      filePath: report_info_reponse.reportFile.filePath,
    });

    return file_response;
  } else {
    thunkAPI.rejectWithValue("Couldn't fetch FSA report.");
    throw new Error("Couldn't fetch FSA report.");
  }
});

const authSlice = createSlice({
  name: 'dashboard',
  initialState: {
    marketplaceOrders: <MarketplaceOrder[]>[],
    marketplaceOrdersLoading: false,
    marketplaceOrdersError: false,
    contestEntries: <ContestEntry[]>[],
    contestEntriesLoading: false,
    contestEntriesError: false,
    YuOrderFSASalesReport: <any>[],
    YuOrderFSASalesReportLoading: false,
    YuOrderFSASalesReportError: false,
    YuOrderFSASalesOrders: <any>[],
    YuOrderFSASalesOrdersLoading: false,
    YuOrderFSASalesOrdersError: false,
    YuSellSalesReport: <any>[],
    YuSellSalesReportLoading: false,
    YuSellSalesReportError: false,
    FSAReportData: <MerchantStore[]>[],
    FSAReportLoading: false,
    FSAReportError: false,
  },
  reducers: {},
  extraReducers: {
    [fetchMarketplaceorders.pending.type]: (state, action) => {
      state.marketplaceOrdersLoading = true;
      state.marketplaceOrdersError = false;
    },
    [fetchMarketplaceorders.fulfilled.type]: (state, action) => {
      state.marketplaceOrdersLoading = false;
      state.marketplaceOrdersError = false;
      state.marketplaceOrders = action.payload.data;
    },
    [fetchMarketplaceorders.rejected.type]: (state, action) => {
      state.marketplaceOrdersLoading = false;
      state.marketplaceOrdersError = true;
    },

    [fetchContestRecords.pending.type]: (state, action) => {
      state.contestEntriesLoading = true;
      state.contestEntriesError = false;
    },
    [fetchContestRecords.fulfilled.type]: (state, action) => {
      state.contestEntriesLoading = false;
      state.contestEntriesError = false;
      state.contestEntries = action.payload.data;
    },
    [fetchContestRecords.rejected.type]: (state, action) => {
      state.contestEntriesLoading = false;
      state.contestEntriesError = true;
    },

    [fetchYuOrderFSASalesReport.pending.type]: (state, action) => {
      state.YuOrderFSASalesReportLoading = true;
      state.YuOrderFSASalesReportError = false;
    },
    [fetchYuOrderFSASalesReport.fulfilled.type]: (state, action) => {
      state.YuOrderFSASalesReportLoading = false;
      state.YuOrderFSASalesReportError = false;
      state.YuOrderFSASalesReport = action.payload.data;
    },
    [fetchYuOrderFSASalesReport.rejected.type]: (state, action) => {
      state.YuOrderFSASalesReportLoading = false;
      state.YuOrderFSASalesReportError = true;
    },

    [fetchYuOrderOrderForFSA.pending.type]: (state, action) => {
      state.YuOrderFSASalesOrdersLoading = true;
      state.YuOrderFSASalesOrdersError = false;
    },
    [fetchYuOrderOrderForFSA.fulfilled.type]: (state, action) => {
      state.YuOrderFSASalesOrdersLoading = false;
      state.YuOrderFSASalesOrdersError = false;
      state.YuOrderFSASalesOrders = action.payload.data;
    },
    [fetchYuOrderOrderForFSA.rejected.type]: (state, action) => {
      state.YuOrderFSASalesOrdersLoading = false;
      state.YuOrderFSASalesOrdersError = true;
    },

    [fetchFSAReport.pending.type]: (state, action) => {
      state.FSAReportLoading = true;
      state.FSAReportError = false;
    },
    [fetchFSAReport.fulfilled.type]: (state, action) => {
      state.FSAReportLoading = false;
      state.FSAReportError = false;
      state.FSAReportData = action.payload.data;
    },
    [fetchFSAReport.rejected.type]: (state, action) => {
      state.FSAReportLoading = false;
      state.FSAReportError = true;
    },

    [fetchYuSellSalesReport.pending.type]: (state, action) => {
      state.YuSellSalesReportLoading = true;
      state.YuSellSalesReportError = false;
    },
    [fetchYuSellSalesReport.fulfilled.type]: (state, action) => {
      state.YuSellSalesReportLoading = false;
      state.YuSellSalesReportError = false;
      state.YuSellSalesReport = action.payload.data;
    },
    [fetchYuSellSalesReport.rejected.type]: (state, action) => {
      state.YuSellSalesReportLoading = false;
      state.YuSellSalesReportError = true;
    },
  },
});

export default authSlice.reducer;
