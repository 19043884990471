import React from 'react';
import { WholesalePurchaseOrder } from '../../data/models/wholesalePurchaseOrder.model';
import { formatDate, formatTime } from '../../shared/utils/common';

interface ShopperAccount {
  fullName?: string;
  phoneNumber?: string;
}

interface WholesalePurchaseOrderCardProps {
  order?: WholesalePurchaseOrder | null;
  selectPurchaseOrderId: string | null;
  handleOrderSelection: (id: string) => void;
}

const WholesalePurchaseOrderCard: React.FC<WholesalePurchaseOrderCardProps> = ({
  order,
  selectPurchaseOrderId,
  handleOrderSelection,
}) => {
  if (!order) {
    return null;
  }

  const isSelected = selectPurchaseOrderId === order.purchaseOrderId;

  return (
    <li
      style={{
        cursor: 'pointer',
        padding: '10px',
        marginBottom: '10px',
        border: isSelected ? '2px solid #007BFF' : '1px dotted #007BFF',
        borderRadius: '5px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#f8f9fa',
      }}
      onClick={() => handleOrderSelection(order.purchaseOrderId ?? '')}
    >
      <div>
        <div style={{ display: 'flex', justifyContent:"end" }}>
          <input
            type="checkbox"
            checked={isSelected}
            onChange={() => handleOrderSelection(order.purchaseOrderId ?? '')}
            style={{ marginRight: '10px', pointerEvents: 'none' }}
          />
        </div>
        <p>Order Id: {order.displayOrderId}</p>
        <p>Shopper Name: {order.shopperAccount?.fullName ?? ''}</p>
        <p>Shopper Contact: {order.shopperAccount?.phoneNumber ?? ''}</p>
        {order.orderTimestamp && (
          <p>
            Order Creation Time:
            {formatTime(order.orderTimestamp.toString())}-
            {formatDate(order.orderTimestamp.toString())}
          </p>
        )}
        <p>Total: {order.total}</p>
        <p>Order Status: {order.orderStatus}</p>
      </div>
    </li>
  );
};

export default WholesalePurchaseOrderCard;
