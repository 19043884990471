import React from 'react';
import { Route, Routes } from 'react-router';
import ReportView from '../pages/Reports/ReportView';
import OrderSearch from '../pages/Reports/OrderSearch';
import SignupsReportView from '../pages/Reports/SignupsReportView';
import YuSellOrders from '../pages/Reports/YuSellOrders';

const ReportsRouter = () => {
  return (
    <Routes>
      <Route path="/fsa-yuOrder-sales" element={<ReportView />} />
      <Route path="/yuSell-sales" element={<YuSellOrders />} />
      <Route path="/fsa-yuOrder-search" element={<OrderSearch />} />
      <Route path="/fsa-signups" element={<SignupsReportView />} />
    </Routes>
  );
};

export default ReportsRouter;
