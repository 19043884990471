import { constants } from '../../config';
import { get } from './services.common';

export const searchPurchaseOrderService = async (data: { displayOrderId: string; supplierCode: string }) => {
  const url =
    constants.endpoint +
    `/store/WholesalePurchaseOrder/admin/coupon-eligible-search?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo&displayOrderId=${data.displayOrderId}`;
  const response = await get(url, {});
  return response;
};

export const fetchGoodwillCouponList = async () => {
  const url =
    constants.endpoint +
    `/store/YuOrderCoupons/goodwillCoupon-list?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo`;
  const response = await get(url, {});
  return response;
};

export const rewardGoodWillCoupon = async (data: { couponId: string; purchaseOrderId: string, expiryDate: string }) => {
  const url =
    constants.endpoint +
    `/store/YuOrderCoupons/reward-goodwill-coupon?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo&purchaseOrderId=${data.purchaseOrderId}&couponId=${data.couponId}&expiryTimestamp=${data.expiryDate}`;
  const response = await get(url, {});
  return response;
};
