import { constants } from "../../config";
import { YFStorageFile } from "../../data/models/tutorial.model";

export const getImageUrl = (image: YFStorageFile) => {
    return `${constants.endpoint}/media/mediacontent?filePath=${image.filePath}&fileName=${image.fileName}`;
}

export const getYoutubeVideoId = (videoUrl: string) => {
    const urlObj = new URL(videoUrl);
    const videoId = new URLSearchParams(urlObj.search).get('v');
    return videoId;
}

export const convertToStartOfDayUTC = (inputDate: Date) => {
    // Convert input to a Date object if it is not already one
    const localDate = inputDate;
    
    // Set the time to the start of the day in local time
    localDate.setHours(0, 0, 0, 0);
  
    // Convert the local start-of-day date to UTC
    const utcDate = new Date(localDate.toUTCString());
    console.log("utcDate");
    console.log(utcDate);
    // Return the date in ISO string format
    return utcDate.toISOString();
  }


  export const formatDate = (dateString: string) => {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleDateString('en-US', {
      day: '2-digit',
      month: 'short',
      year: '2-digit',
    });
  };

  export const formatTime = (dateString: string) => {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleTimeString('en-US', {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    });
  };