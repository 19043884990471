import React, { useState, useEffect } from 'react';
import { Table, Select, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../data/storeConfig';
import { RootState } from '../../data/reducers';
import * as dashboardActions from '../../data/reducers/dashboard.reducer';

const { Option } = Select;

// Define TypeScript interfaces
interface YuSellProductLibrary {
  productType: string;
  quantity: number;
  price: number;
}

interface YuSellVoucherBuyerCustomer {
  name: string;
  contactNo: string;
  email?: string;
}

interface ApproverInfo {
  ambassadorReferralId: string;
  isApproved: boolean;
  timestamp: Date | null;
}

interface YuSellOrder {
  id: string;
  total: number;
  yuSellVoucherBuyerCustomer: YuSellVoucherBuyerCustomer;
  yuSellProductLibraries: YuSellProductLibrary[];
  approvalInfo: ApproverInfo | null;
  orderStatus: string;
  displayOrderId: string;
}

type BeneficiaryType = 'SHOPPER' | 'MERCHANT';

const YuSellOrders: React.FC = () => {
  const [orderData, setOrderData] = useState<YuSellOrder[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<BeneficiaryType>('MERCHANT');

  const dispatch = useDispatch<AppDispatch>();
  const { YuSellSalesReport, YuSellSalesReportError, YuSellSalesReportLoading } = useSelector(
    (state: RootState) => state.dashboard,
  );

  useEffect(() => {
    fetchOrders(filter);
  }, [filter]);

  const fetchOrders = async (beneficiaryType: BeneficiaryType) => {
    dispatch(dashboardActions.fetchYuSellSalesReport({ beneficiaryType: beneficiaryType }));
  };

  useEffect(() => {
    setOrderData(YuSellSalesReport);
  }, [YuSellSalesReport]);

  const handleFilterChange = (value: BeneficiaryType) => {
    setFilter(value);
  };

  const columns: ColumnsType<YuSellOrder> = filter == "SHOPPER" ? [
    {
      title: "Order ID",
      dataIndex: "displayOrderId",
      key: "displayOrderId",
      render: (value: string) => {
        // if (!value) return "-"; // Handle cases where value might be null/undefined
        // const parts = value.split("-");
        // return parts[parts.length - 1].toUpperCase();
        return value.toUpperCase();
      },
    }, 
    {
      title: 'Purchaser Name',
      dataIndex: ['yuSellVoucherBuyerCustomer', 'name'],
      key: 'purchaserName',
    },
    {
      title: 'Contact Number',
      dataIndex: ['yuSellVoucherBuyerCustomer', 'contactNo'],
      key: 'contactNo',
    },
    {
      title: "Products",
      key: "products",
      render: (_, record) => (
        <Table<YuSellProductLibrary>
          dataSource={record.yuSellProductLibraries}
          columns={[
            {
              title: "Product Type",
              dataIndex: "productType",
              key: "productType",
            },
            {
              title: "Quantity",
              dataIndex: "quantity",
              key: "quantity",
            },
            {
              title: "Price",
              dataIndex: "sellingPrice",
              key: "sellingPrice",
            },
          ]}
          rowKey={(product) => product.productType}
          pagination={false}
          bordered
        />
      ),
    },
    {
      title: 'Total Price',
      dataIndex: 'total',
      key: 'totalPrice',
    },
  ] : [
    {
      title: "Order ID",
      dataIndex: "displayOrderId",
      key: "displayOrderId",
      render: (value: string) => {
        // if (!value) return "-"; // Handle cases where value might be null/undefined
        // const parts = value.split("-");
        // return parts[parts.length - 1].toUpperCase();
        return value.toUpperCase();
      },
    },    
    {
      title: 'FSA',
      dataIndex: ['approvalInfo', 'ambassadorReferralId'],
      key: 'FSACode',
    },
    {
      title: 'Approval Status',
      dataIndex: ['approvalInfo', 'isApproved'],
      key: 'isApproved',
      render: (value: boolean) => {
        // if (!value) return "-"; // Handle cases where value might be null/undefined
        // const parts = value.split("-");
        // return parts[parts.length - 1].toUpperCase();
        return value == true ? "APPROVED" : "PENDING APPROVAL";
      },
    },
    {
      title: 'Order Status',
      dataIndex: 'orderStatus',
      key: 'orderStatus',
    },
    {
      title: "Products",
      key: "products",
      render: (_, record) => (
        <Table<YuSellProductLibrary>
          dataSource={record.yuSellProductLibraries}
          columns={[
            {
              title: "Product Name",
              dataIndex: "productType",
              key: "productType",
            },
            {
              title: "Quantity",
              dataIndex: "quantity",
              key: "quantity",
            },
            {
              title: "Price",
              dataIndex: "sellingPrice",
              key: "sellingPrice",
            },
          ]}
          rowKey={(product) => product.productType}
          pagination={false}
          bordered
        />
      ),
    },
    {
      title: 'Total Price',
      dataIndex: 'total',
      key: 'totalPrice',
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <Select
        defaultValue="MERCHANT"
        onChange={(value) => handleFilterChange(value as BeneficiaryType)}
        style={{ width: 200, marginBottom: '20px' }}
      >
        <Option value="MERCHANT">SALE</Option>
        <Option value="SHOPPER">RESALE</Option>
      </Select>
      <Table<YuSellOrder>
        dataSource={orderData}
        columns={columns}
        rowKey={(record) => record.displayOrderId}
        loading={loading}
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
};

export default YuSellOrders;
