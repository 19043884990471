import { constants } from '../../config';
import { convertToStartOfDayUTC } from '../../shared/utils/common';
import { get } from './services.common';

export const fetchMarketplaceordersService = async () => {
  const url =
    constants.endpoint +
    `/store/OnlineOrders/orders-list?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo&merchantStoreId=${constants.MARKETPLACE_ID}`;
  const response = await get(url, {});
  return response;
};

export const fetchContestRecordsService = async (data: {contestId: string}) => {
  const url =
    constants.endpoint +
    `/token-engine/Contest/contest-record/getall?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo&contestId=${data.contestId}`;
  const response = await get(url, {});
  return response;
};

export const fetchYuOrderFSASalesReportService = async (data: {supplierCode: string, startDate: Date, endDate: Date}) => {
  console.log(data.startDate)
  const url =
    constants.endpoint +
    `/store/WholesaleStoreReporting/sales-report/by-supplier?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo&supplierCode=${data.supplierCode}&startDate=${convertToStartOfDayUTC(data.startDate)}&endDate=${convertToStartOfDayUTC(data.endDate)}`;
  const response = await get(url, {});
  return response;
};

export const fetchYuSellSalesReportService = async (data: { beneficiaryType: string }) => {
  const url =
    constants.endpoint +
    `/store/YuSellOttVoucher/admin/all/orders?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo&beneficiaryType=${data.beneficiaryType}`;
  const response = await get(url, {});
  return response;
};

export const fetchYuOrderOrderForFSAService = async (data: {supplierCode: string, searchQuery: string}) => {
  const url =
    constants.endpoint +
    `/store/WholesalePurchaseOrder/admin/search?key=W9BBwlJHtV7e1gli2m0PHEUUDyrwEOqVyBzqI9hBRbVIePXKZYDc0l6F9R9Jn1Mo&supplierCode=${data.supplierCode}&displayOrderId=${data.searchQuery}`;
  const response = await get(url, {});
  return response;
};

export const fetchFSAReportService = async () => {
  const url =
    constants.endpoint +
    `/reporting/data/fsa-signups?key=Tx6nuzaGL5jsACkPkQktdG74MNFm9Mh7JvLAymXBk3epNt7T3AAa6F7TbvgeVMvSR6x8d2xKxR63QehyhwzQLbRPT`;
  const response = await get(url, {});
  return response;
};

export const fetchMediaService = async (data: {fileName: string, filePath: string}) => {
  const url =
    constants.endpoint +
    `/media/mediacontent?filePath=${data.filePath}&fileName=${data.fileName}`;
  const response = await get(url, {});
  return response;
};
